







































import {Component, Vue} from 'vue-property-decorator'
import ResourceInfo from './resource-info.vue'
import ResourceContent from './resource-content.vue'
import {createRequest} from '~/utils/network-request'
import {DseResourceGroup, DseResourceGroupEditable} from '~/components/models/dse-resource-models'
import {AdminPermission} from '~/utils/permissions'

@Component({
    components: {
        ResourceInfo,
        ResourceContent
    },
    metaInfo() {
        return {
            title: 'Resource Detail'
        }
    }
})
export default class ResourceEditor extends Vue {
    static permission = AdminPermission.DSERecources

    resource: DseResourceGroup = new DseResourceGroup()
    orgResources = ""
    edited: DseResourceGroupEditable = new DseResourceGroupEditable()
    lockSave = false
    disableSave = false

    activeIndex: string = '0'

    handleNavMenuChange(key, keyPath) {
        // this.activeIndex = key;
        switch (key) {
            case '0':
                this.activeIndex = key
                this.$router.push({query: {tab: 'info'}})
                break
            case '1':
                this.activeIndex = key
                this.$router.push({query: {tab: 'content'}})
                break
            default:
                this.activeIndex = key
                break
        }
    }

    missingFields(): string {
        let missed: string = ''
        return missed
    }

    haveSpecialCharactersInURLTitle(): boolean {
        // let format = /[\s\t~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        const format = /[\s\t~`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/
        if (format.test(this.resource.url_slug)) {
            return true
        }
        return false
    }
    async onSubmit() {
        if (this.missingFields() != '') {
            // Missed fields
            const missingMessage: string = 'Missed fields: ' + this.missingFields() + 'please fill in all of them!'
            alert(missingMessage)
            return
        }

        if (this.haveSpecialCharactersInURLTitle()) {
            alert('URL Title have special characters')
            return
        }

        // POST resource
        const body: {} = {}

        const loading = this.$loading({
            lock: true,
            text: 'Updating',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        loading.close()
    }
    async created() {
        // Edit resource
        const id = Number(this.$route.params['id'])
        if (isNaN(id))
            return
        await this.getResource()

        const tab = this.$route.query['tab']
        if (tab) {
            switch (tab) {
                case 'info':
                    this.activeIndex = '0'
                    break
                case 'content':
                    this.activeIndex = '1'
                    break
                default:
                    break
            }
        }
    }
    async getResource() {
        const id = this.$route.params['id']
        const res = await createRequest(`/dse-resource-group/${id}`, 'get', {inc_view: 'false'}).send()
        this.resource = DseResourceGroup.from(res.data.resource)
        let i = 0
        for(const r of this.resource.resources) {
            r.id = i++
        }
        this.orgResources = JSON.stringify(this.resource.resources)
        this.edited = DseResourceGroupEditable.from(res.data.resource)
    }
    async save() {
        this.lockSave = true
        const body = {}
        for(const key of Object.keys(this.edited)) {
            if(this.edited[key] !== this.resource[key])
                body[key] = this.edited[key]
        }
        if(this.orgResources !== JSON.stringify(this.edited.resources))
            body["resources"] = this.edited.resources
        const id = this.$route.params['id']
        await createRequest(`/dse-resource-group/${id}`, 'patch', {}, body).send()
        await this.getResource()
        this.lockSave = false
        this.$message({
            message: 'Success',
            type: 'success'
        })
    }
}
