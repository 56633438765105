





































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator'

import DropZone from '~/components/dropzone/index.vue'
import {
    DseResource,
    FileResource,
    ImageResource,
    ResourceType,
    VideoResource,
    VideoType
} from '~/components/models/dse-resource-models'
import {createRequest, DataObject} from '~/utils/network-request'
import axios from 'axios'
import draggable from 'vuedraggable/dist/vuedraggable.umd.js'

// https://gist.github.com/jrtaylor-com/42883b0e28a45b8362e7
function youtubeDurationToSeconds(duration) {
    let hours = 0
    let minutes = 0
    let seconds = 0

    // Remove PT from string ref: https://developers.google.com/youtube/v3/docs/videos#contentDetails.duration
    duration = duration.replace('PT', '')

    // If the string contains hours parse it and remove it from our duration string
    if (duration.indexOf('H') > -1) {
        const hours_split = duration.split('H')
        hours = parseInt(hours_split[0])
        duration = hours_split[1]
    }

    // If the string contains minutes parse it and remove it from our duration string
    if (duration.indexOf('M') > -1) {
        const minutes_split = duration.split('M')
        minutes = parseInt(minutes_split[0])
        duration = minutes_split[1]
    }

    // If the string contains seconds parse it and remove it from our duration string
    if (duration.indexOf('S') > -1) {
        const seconds_split = duration.split('S')
        seconds = parseInt(seconds_split[0])
    }

    // Math the values to return seconds
    return (hours * 60 * 60) + (minutes * 60) + seconds
}

@Component({
    components: {
        DropZone,
        draggable
    }
})
export default class ResourceLanding extends Vue {
    @Prop({
        default: () => {
            return []
        }
    })
    value!: DseResource[]

    ResourceType = ResourceType
    VideoType = VideoType

    resourceUploading: DseResource = new DseResource()

    typeOptions = [
        {id: ResourceType.Video, label: 'Video'},
        {id: ResourceType.PDF, label: 'PDF'},
        {id: ResourceType.Image, label: 'Image'},
        {id: ResourceType.Other, label: 'Other'},
    ]
    videoTypeOptions = [
        {id: VideoType.YouTube, label: 'YouTube'},
        {id: VideoType.Vimeo, label: 'Vimeo'},
    ]
    videoThumbnails = {}

    maxId = 0

    getMaxId() {
        this.maxId = this.value.reduce((m, r) => {
            return Math.max(m, r.id)
        }, this.maxId)
        return this.maxId
    }

    fileUrlDict = {}

    get dropzoneUrl() {
        return `${process.env.VUE_APP_DOMAIN_API}/file/large`
    }

    created() {
        for (const [i, r] of this.value.entries()) {
            if (r.type === ResourceType.Video) {
                this.updateVideoData(r as VideoResource).then().catch()
            } else {
                this.fileUrlDict[r.id.toString()] = this.getFileUrl(r)
            }
        }
    }

    newResource() {
        const r = new VideoResource()
        r.id = this.getMaxId() + 1
        this.value.push(r)
    }

    removeResource(resource: DseResource) {
        this.value.splice(this.value.indexOf(resource), 1)
    }
    async updateVideoData(vr: VideoResource) {
        if (vr.video_type === VideoType.Vimeo) {
            try {
                const res = await createRequest(`/vimeo/${vr.video_id}/data`, 'get').send()
                const thumbnailId = res.data.pictures.uri.split('/').pop()
                this.videoThumbnails[vr.video_id] = `https://i.vimeocdn.com/video/${thumbnailId}_320.jpg`
                vr.size = res.data.duration
            } catch (e) {
                console.error(e)
            }
        } else {
            this.videoThumbnails[vr.video_id] = `https://img.youtube.com/vi/${vr.video_id}/mqdefault.jpg`
            //
            try {
                const res = await axios.request<DataObject>({
                    method: 'get',
                    url: `https://www.googleapis.com/youtube/v3/videos?id=${vr.video_id}&part=contentDetails&key=AIzaSyBSlABuR6l1ohSfUjuARwUpTrvAdZj9DV8`
                })
                const duration = res.data.items[0].contentDetails.duration
                vr.size = youtubeDurationToSeconds(duration)
            } catch (e) {
                console.error(e)
            }
        }
        this.fileUrlDict[vr.id.toString()] = this.videoThumbnails[vr.video_id]
    }

    fileAdded(resource) {
        this.resourceUploading = resource
    }

    setFileUrl(resource: DseResource, url: string) {
        if (resource.type === ResourceType.Image) {
            (resource as ImageResource).image_url = url
        } else {
            (resource as FileResource).file_url = url
        }
    }

    getFileUrl(resource: DseResource) {
        const url = (resource.type === ResourceType.Image) ?
            (resource as ImageResource).image_url :
            (resource as FileResource).file_url
        return url || ''
    }

    dropzoneUploadCompleted(file, elem, response) {
        this.setFileUrl(this.resourceUploading, response['file_url'])
        this.resourceUploading.size = file.size
        this.fileUrlDict[this.resourceUploading.id.toString()] =
            this.videoThumbnails[response['file_url']]
    }


    HHMMSS(seconds: number): string {
        const hh = Math.floor(seconds / 3600)
        const mm = Math.floor((seconds - (hh * 3600)) / 60)
        const ss = seconds - (hh * 3600) - (mm * 60)

        let str = ''
        if (hh > 0) {
            if (hh < 10)
                str += '0'
            str += hh + ':'
        }

        if (mm < 10)
            str += '0'
        str += mm + ':'
        if (ss < 10)
            str += 0
        str += ss

        return str
    }

    humanFileSize(bytes, si) {
        let thresh = si ? 1000 : 1024
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B'
        }
        let units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
        let u = -1
        do {
            bytes /= thresh
            ++u
        } while (Math.abs(bytes) >= thresh && u < units.length - 1)
        return bytes.toFixed(1) + ' ' + units[u]
    }

    @Emit()
    input(value: DseResource[]) {
        //
    }

    get localValue(): DseResource[] {
        return this.value
    }

    set localValue(value: DseResource[]) {
        this.input(value)
    }
}

