


































































































import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator'

import DropZone from '~/components/dropzone/index.vue'
import {DseResourceGroup, DseResourceGroupEditable} from '~/components/models/dse-resource-models'
import Ckeditor from '~/components/ckeditor.vue'
import UrlSlugInput from '~/components/url-slug-input.vue'

@Component({
    components: {
        DropZone,
        Ckeditor,
        UrlSlugInput
    },
})
export default class ResourceLanding extends Vue {
    @Prop({
        default: () => {
            return new DseResourceGroup()
        }
    })

    value!: DseResourceGroupEditable

    isDialogShown: boolean = false

    isWarningVisible: boolean = false

    urlSlugUsed = false

    urlSlugUsedDoc: DseResourceGroupEditable | null = null

    get dropzoneUrl() {
        return `${process.env.VUE_APP_DOMAIN_API}/file`
    }

    dropzoneUploadCompleted(file, elem, response) {
        this.value.thumbnail_url = response['file_url']
    }

    created() {
    }

    urlTitleInputOnFocus() {
        if (this.$route.params['id'] && !this.isDialogShown) {
            this.isDialogShown = true
            this.$alert('You are editing the url title! Please make sure you know what you are doing.', 'CAUTION', {
                confirmButtonText: 'OK'
            })
        }
    }

    urlSlugInputOnChange() {
        if (this.$route.params['id']) {
            this.isWarningVisible = true
        }
    }

    @Watch('urlSlugUsed')
    @Emit('update:disableSave')
    urlSlugUsedChanged() {}

    @Emit()
    input(value: DseResourceGroupEditable) {
        //
    }

    get localValue(): DseResourceGroupEditable {
        return this.value
    }

    set localValue(value: DseResourceGroupEditable) {
        this.input(value)
    }
}

